<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M40.8612 23.0635H7.11556C6.48806 23.0635 6 23.691 6 24.4579C6 25.2249 6.5113 25.8524 7.11556 25.8524H40.8844C41.5119 25.8524 42 25.2249 42 24.4579C42 23.691 41.4887 23.0635 40.8612 23.0635Z"
        fill="inherit"/>
    <path
        d="M23.9883 32.1281C20.479 32.1281 17.5274 29.7575 16.621 26.5503H13.7391C14.7152 31.3147 18.9451 34.917 23.9883 34.917C29.0316 34.917 33.2614 31.3147 34.2375 26.5503H31.3557C30.4493 29.7575 27.4977 32.1281 23.9883 32.1281Z"
        fill="inherit"/>
    <path
        d="M23.9883 16.7889C27.4977 16.7889 30.4493 19.1595 31.3557 22.3667H34.2375C33.2614 17.6023 29.0316 14 23.9883 14C18.9451 14 14.7152 17.6023 13.7391 22.3667H16.621C17.5274 19.1595 20.479 16.7889 23.9883 16.7889Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-straightening-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
